import { AuthToken } from "../../lib/auth_token";
import React, { Component } from "react";
import { getTokenCookie } from "../../lib/utils/auth-cookies-new";

export default function withAuth(WrappedComponent, shouldRedirect = true) {
  return class extends Component {
    static async getInitialProps(ctx) {
      const callbackPath = ctx.req?.url;
      const token = getTokenCookie(ctx);
      const auth = new AuthToken(token);
      const initialProps = { auth };
      if (auth.isExpired) {
        if (shouldRedirect) {
          let pageName = "login";
          ctx.res?.writeHead(302, {
            Location: this.buildAuthUrl(
              pageName,
              callbackPath,
            ),
          });
          ctx.res?.end();
        }
      }
      if (WrappedComponent.getInitialProps) {
        const componentProps = await WrappedComponent.getInitialProps(ctx);
        return {
          ...componentProps,
          ...initialProps,
        };
      }
      return initialProps;
    }

    static buildAuthUrl(
      authPage,
      callbackPath,
    ) {
      let finalUrl = `/${authPage}?redirected=true&callbackUrl=${encodeURIComponent(
        callbackPath || ""
      )}`;
      return finalUrl;
    }

    get auth() {
      // the server pass to the client serializes the token
      // so we have to reinitialize the authToken class
      //
      // @see https://github.com/zeit/next.js/issues/3536
      return new AuthToken(this.props.auth?.token);
    }

    render() {
      return <WrappedComponent {...this.props} auth={this.auth} />;
    }
  };
}
