import withLogin from "../components/Login/withLogin";
import withAuth from "../components/decorator/WithAuth";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Layout from "../components/Layout/Layout";

const LoginPage = ({ initLogin, auth }) => {
  const router = useRouter();
  useEffect(() => {
    handleLogin();
  }, []);
  const handleLogin = () => {
    const callbackUrl = router.query.callbackUrl;
    if (auth?.isValid) {
      router.push(callbackUrl);
    } else {
      initLogin();
    }
  };
  return null;
};

export default withAuth(withLogin(LoginPage), false);
