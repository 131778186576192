import React, { useState } from "react";
import LoginPopup from "./LoginPopup";
import { loginApis } from "./LoginApis";
import { useRouter } from "next/router";
import { removeTokenCookie, setTokenCookie } from "../../lib/utils/auth-cookies-new";

const Login = ({ isModalOpen, handleClose }) => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [activeView, setActiveView] = useState("phone");
  const [correlationId, setCorrelationId] = useState("");
  const [phoneText, setPhoneText] = useState("Get OTP");
  const [phoneErrMsg, setPhoneErrMsg] = useState("");
  const [otpText, setOtpText] = useState("Proceed");
  const [otpErrMsg, setOtpErrMsg] = useState("");
  const [otpSuccessMsg, setOtpSuccessMsg] = useState("");

  const router = useRouter();
  const callbackUrl = router.query.callbackUrl;

  const generateOtp = async () => {
    if (phone.length !== 10) {
      setPhoneErrMsg("Please enter a valid 10 digit number");
      return;
    } else {
      setPhoneErrMsg("");
      setPhoneText("Please wait");
      try {
        const res = await loginApis.generateOtp("91", "INDIA", phone);
        if (res.success) {
          setCorrelationId(res.data);
          setActiveView("otp");
        } else {
          setPhoneErrMsg(res.message);
        }
      } catch (err) {
        setPhoneErrMsg("Something went wrong. Please try after sometime");
      } finally {
        setPhoneText("Get OTP");
      }
    }
  };

  const validateOtp = async () => {
    setOtpErrMsg("");
    setOtpText("Please wait");
    try {
      const res = await loginApis.validateOtp(correlationId, otp.join(""));
      if (res.success) {
        await storeToken(res.data);
      } else {
        setOtpErrMsg(res.message);
      }
    } catch (err) {
      console.log(err);
      removeTokenCookie(null, "token");
      setOtpErrMsg("OTP is invalid");
    } finally {
      setOtpText("Proceed");
    }
  };

  const storeToken = async (token) => {
    setTokenCookie(null, token);
    router.push(
      `/api/postLogin${callbackUrl ? `?callbackUrl=${callbackUrl}` : ""}`
    );
  };

  const resendOtp = async () => {
    try {
      const res = await loginApis.resendOtp(country, correlationId);
      if (res.success) {
        setOtpSuccessMsg("OTP resent successfully");
      } else {
        setOtpErrMsg(res.message);
      }
    } catch (err) {
      setOtpErrMsg("Something went wrong. Please try after sometime");
    } finally {
      setTimeout(() => {
        setOtpSuccessMsg("");
      }, 5000);
    }
  };

  return (
    <LoginPopup
      phone={phone}
      handlePhoneChange={(e) => {
        setPhone(e.target.value);
      }}
      activeView={activeView}
      handleViewChange={(view) => setActiveView(view)}
      isModalOpen={isModalOpen}
      handleClose={handleClose}
      generateOtp={generateOtp}
      otp={otp}
      handleOtpChange={(val) => setOtp(val)}
      validateOtp={validateOtp}
      resendOtp={resendOtp}
      phoneText={phoneText}
      phoneErrMsg={phoneErrMsg}
      otpText={otpText}
      otpErrMsg={otpErrMsg}
      otpSuccessMsg={otpSuccessMsg}
    />
  );
};

export default Login;
