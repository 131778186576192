import { useRef, useEffect } from "react";

const EnterPhoneView = ({
  phone,
  phoneText,
  phoneErrMsg,
  phoneInfoMsg,
  handleChange,
  generateOtp,
}) => {
  const hadSentEvent = useRef(false);

  const phoneRef = useRef(null);

  const handlePhoneChange = (e) => {
    handleChange(e);
  };

  useEffect(() => {
    phoneRef.current.focus();
  }, []);

  return (
    <>
      <div
        className={`flex items-center w-full rounded border  bg-lightgray-1350 ${
          phone.length === 10 ? "border-green-300" : "border-lightgray-1350"
        }`}
      >
        <div className="font-bold md:text-lg text-base pl-4 pr-2 border-r border-gray-300">
          +91
        </div>
        <input
          ref={phoneRef}
          className="md:text-lg text-base md:p-4 p-3 bg-transparent"
          type="number"
          value={phone}
          onChange={handlePhoneChange}
          placeholder="Mobile Number"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              generateOtp();
            } else {
              if (!hadSentEvent.current) {
                hadSentEvent.current = true;
              }
            }
          }}
        />
      </div>
      {!phoneErrMsg && phoneInfoMsg && (
        <p className="text-sm text-primary text-left py-1 w-full">
          {phoneInfoMsg}
        </p>
      )}
      {phoneErrMsg && (
        <p className="text-sm text-red-600 text-left py-1 w-full">
          {phoneErrMsg}
        </p>
      )}
      <button
        disabled={phone.length !== 10}
        className={`my-4 md:my-6 w-full rounded text-white py-4 ${phone.length !== 10 ? "cursor-not-allowed bg-gray-200" : "bg-primary"}`}
        ctaText={phoneText}
        onClick={generateOtp}
      >
        {phoneText}
      </button>
    </>
  );
};

export default EnterPhoneView;
