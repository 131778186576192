import axios from "axios";

const generateOtp = async (countryCode, country, phone) => {
  const appName = process.env.AUTH_APP_NAME;
  try {
    const res = await axios.post(
      `/api/auth?path=otp/generate/${appName}/%2B${countryCode}${phone}/&nocache=${new Date().getTime()}&isWebRequest=${true}&whatsappConsent=false`,
      null,
      {
        params: {
          country_code: country,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const validateOtp = async (corelationId, otp) => {
  const appName = process.env.AUTH_APP_NAME;
  try {
    const res = await axios.post(
      `/api/auth?path=otp/validate/${appName}/${corelationId}/${otp}/false&nocache=${new Date().getTime()}&isWebRequest=${true}`
    );
    if (res?.data?.success) {
      localStorage.setItem("userDataCollectedByChatBot", "true");
    }
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

const resendOtp = async (country, corelationId) => {
  const appName = process.env.AUTH_APP_NAME;
  try {
    const res = await axios.post(
      `/api/auth?path=otp/resend/${appName}/${corelationId}&nocache=${new Date().getTime()}&isWebRequest=${true}&whatsappConsent=false`,
      null,
      {
        params: {
          country_code: country,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
};

export const loginApis = {
  generateOtp,
  validateOtp,
  resendOtp,
};
