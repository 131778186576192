import { useState } from "react";
import Login from "../Login";

const withLogin = (Component) => (props) => {
  const [showLoginView, setShowLoginView] = useState(false);

  const initLogin = () => {
    setShowLoginView(true);
  };

  return (
    <>
      <Component {...props} initLogin={initLogin} />
      {showLoginView && (
        <Login
          handleClose={() => setShowLoginView(false)}
          isModalOpen={showLoginView}
        />
      )}
    </>
  );
};

export default withLogin;
