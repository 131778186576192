import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import EnterPhoneView from "./EnterPhoneView";
import EnterOtpView from "./EnterOtpView";

const LoginPopup = ({
  isModalOpen,
  activeView,
  phone,
  otp,
  phoneText,
  otpText,
  phoneErrMsg,
  otpErrMsg,
  otpSuccessMsg,
  phoneInfoMsg,
  handleClose,
  handlePhoneChange,
  handleOtpChange,
  handleViewChange,
  generateOtp,
  validateOtp,
  resendOtp,
}) => {
  return (
    <Modal
      className="flex justify-center items-end md:items-center md:px-4"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isModalOpen}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") handleClose();
      }}
      closeAfterTransition
      disableEscapeKeyDown
    >
      <Fade in={isModalOpen}>
        <div className="relative w-full flex items-center justify-center md:mx-auto">
          <div className="bg-white w-fit py-4 md:py-8 shadow-10 md:rounded-lg px-4">
            <div className="flex flex-col items-center px-4 md:px-16 w-full">
              <div className="flex md:flex-col flex-col-reverse items-center w-full">
                <img
                  className="md:w-40 w-24"
                  src="/assets/logo.svg"
                  alt="leap"
                />
                <p className="md:text-font22 text-lg font-bold text-center pt-5 md:pt-6 pb-2 md:pb-4">
                  {activeView === "phone"
                    ? "Enter your phone number"
                    : "Verify your Phone Number"}
                </p>
              </div>
              {activeView === "phone" ? (
                <EnterPhoneView
                  phone={phone}
                  phoneText={phoneText}
                  phoneErrMsg={phoneErrMsg}
                  handleChange={handlePhoneChange}
                  changeView={handleViewChange}
                  generateOtp={generateOtp}
                  phoneInfoMsg={phoneInfoMsg}
                />
              ) : (
                <EnterOtpView
                  otp={otp}
                  phone={phone}
                  otpText={otpText}
                  otpErrMsg={otpErrMsg}
                  otpSuccessMsg={otpSuccessMsg}
                  handleChange={handleOtpChange}
                  changeView={handleViewChange}
                  submitOtp={validateOtp}
                  resendOtp={resendOtp}
                />
              )}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default LoginPopup;
