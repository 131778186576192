import { useEffect, useRef, useState } from "react";

const EnterOtp = ({
  otp,
  phone,
  otpText,
  otpErrMsg,
  otpSuccessMsg,
  changeView,
  submitOtp,
  resendOtp,
  handleChange,
}) => {
  const [resendOtpCount, setResendOtpCount] = useState(30);
  const resendOtpCountRef = useRef(resendOtpCount);

  useEffect(() => {
    const otpInterval = setInterval(() => {
      if (resendOtpCountRef.current < 1) {
        clearInterval(otpInterval);
      } else {
        resendOtpCountRef.current -= 1;
        setResendOtpCount(resendOtpCountRef.current);
      }
    }, 1000);
    return () => {
      clearInterval(otpInterval);
    };
  }, []);

  const getOtpMsg = () => {
    if (resendOtpCount) {
      return (
        <p className="text-lightgray-400">
          Resend OTP in&nbsp;
          <span className="text-black">
            00:{resendOtpCount < 10 ? `0${resendOtpCount}` : resendOtpCount}
          </span>
        </p>
      );
    } else if (otpSuccessMsg) {
      return <p className="font-bold text-green-100">{otpSuccessMsg}</p>;
    } else {
      return (
        <p
          onClick={resendOtp}
          className="text-primary font-bold cursor-pointer"
        >
          Resend OTP
        </p>
      );
    }
  };

  const otpRef = useRef([]);

  useEffect(() => {
    otpRef.current[0].focus();
  }, []);

  useEffect(() => {
    if (otp?.filter((n) => n)?.length === 6) {
      submitOtp();
    }
  }, [otp]);

  const handleOTP = (event, index) => {
    let newOTP = [...otp];
    let value = event.target.value;
    if (!isNaN(value) && value.length <= 6 - index) {
      if (value.length === 1) {
        newOTP[index] = value;
        if (index < 5) {
          otpRef.current[index + 1].focus();
        }
      } else if (value.length > 1 && value.length <= 6) {
        let offset = index;
        while (offset < index + value.length) {
          newOTP[offset] = value[offset - index];
          if (offset + 1 <= 5) {
            otpRef.current[offset + 1].focus();
          }
          offset += 1;
        }
      }
      handleChange(newOTP);
    }
  };

  const handleDelete = (event, index) => {
    if (event.keyCode === 8) {
      let newOTP = [...otp];
      newOTP[index] = "";
      handleChange(newOTP);
      if (index > 0) {
        otpRef.current[index - 1].focus();
      }
    }
    if (event.key === "Enter" && otp?.filter((n) => n)?.length === 6) {
      submitOtp();
    }
  };

  const checkOTPBoxColor = () => {
    if (otp?.filter((n) => n)?.length === 6 && otpErrMsg.length > 0)
      return "border-red-600";
    else if (otp?.filter((n) => n)?.length === 6 && otpSuccessMsg.length > 0)
      return "border-green-300";
    else return "border-lightgray-1200";
  };

  return (
    <>
      <div className="text-lightgray-400 text-sm pb-4 w-full justify-between flex">
        <p className="font-bold text-black">
          OTP sent to <strong className="text-black">+91 {phone}</strong>{" "}
        </p>
        <button
          className="text-primary font-bold cursor-pointer"
          onClick={() => changeView("phone")}
        >
          Change
        </button>
      </div>
      <div className="flex w-full pb-4 justify-between">
        <div className="flex">
          {" "}
          <p className="text-xs mr-2">Enter OTP</p>
        </div>
        <div className="flex justify-end text-xs">{getOtpMsg()}</div>
      </div>
      <div className="flex items-center w-full justify-between mb-4">
        {[...Array(6)].map((_i, index) => {
          return (
            <input
              className={`md:text-lg text-base md:w-12 md:h-12 w-10 h-10 text-center p-0.5 ${
                index < 5 ? "mr-1" : "mr-0"
              } ${
                index > 0 ? "ml-1" : "ml-0"
              } rounded border  ${checkOTPBoxColor()}`}
              key={index}
              type="number"
              placeholder=""
              value={otp[index]}
              onChange={(e) => handleOTP(e, index)}
              ref={(el) => (otpRef.current[index] = el)}
              onKeyDown={(event) =>
                (event.key === "e" && event.preventDefault()) ||
                handleDelete(event, index)
              }
            />
          );
        })}
      </div>
      {otpErrMsg && otp?.filter((n) => n)?.length === 6 && (
        <p className="text-sm py-1 w-full text-red-600">{otpErrMsg}</p>
      )}
      <button
        disabled={otp?.filter((n) => n)?.length < 6}
        className={`my-4 md:my-6 w-full rounded text-white py-4 ${
          otp?.filter((n) => n)?.length < 6
            ? "cursor-not-allowed bg-gray-200"
            : "bg-primary"
        }`}
        ctaText={otpText}
        onClick={submitOtp}
      >
        {otpText}
      </button>
    </>
  );
};

export default EnterOtp;
